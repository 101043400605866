import { render, staticRenderFns } from "./index.vue?vue&type=template&id=65c2a87a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./index.vue?vue&type=style&index=1&id=65c2a87a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65c2a87a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonProductCard: require('/var/www/interni24.pl/components/skeleton/SkeletonProductCard.vue').default,SkeletonWrapper: require('/var/www/interni24.pl/components/skeleton/SkeletonWrapper.vue').default})
